import { createContext, useContext } from 'react'

export type FilterSelectionState = {
  [category: string]: string[]
}

export type FilterSelectionContextType = {
  selectedFilters: FilterSelectionState
  setSelectedFilters: (items: FilterSelectionState) => void
  addFilter: (category: string, item: string) => void
  removeFilter: (category: string, item: string) => void
  clearFilters: (category?: string) => void
}

export const FilterSelectionContext = createContext<FilterSelectionContextType>(
  {} as FilterSelectionContextType,
)

export const useFilterSelectionContext = () => {
  return useContext(FilterSelectionContext)
}
