import { useRouter } from 'next/router'

import { getLanguageAndCountry } from '@/utils'

// TODO update uses of `getLanguageAndCountry` with this everywhere
export const useLanguageAndCountry = () => {
  const { locale } = useRouter()

  return getLanguageAndCountry(locale)
}
