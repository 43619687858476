import { useState } from 'react'
import { useRefinementList } from 'react-instantsearch-core'

import { useDeepCompareMemo } from 'use-deep-compare'

import type { FilterSelectionState } from '@/contexts/FilterSelectionContext/FilterSelectionContext'
import { FilterSelectionContext } from '@/contexts/FilterSelectionContext/FilterSelectionContext'

export const FilterSelectionProvider = ({ children }) => {
  const { items: refinementList } = useRefinementList({
    attribute: 'language',
  })

  const initialFilters: FilterSelectionState = refinementList
    .filter((refinement) => refinement.isRefined)
    .reduce((acc, refinement) => {
      acc.language = [String(refinement.value)]
      return acc
    }, {} as FilterSelectionState)

  const [selectedFilters, setSelectedFilters] = useState<FilterSelectionState>(initialFilters)

  const addFilter = (category: string, item: string) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: [...(prev[category] || []), item].filter(
        (value, index, self) => self.indexOf(value) === index,
      ),
    }))
  }

  const removeFilter = (category: string, item: string) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: (prev[category] || []).filter((value) => value !== item),
    }))
  }

  const clearFilters = (category?: string) => {
    if (category) {
      setSelectedFilters(() => {
        const updatedFilters = { ...selectedFilters }
        delete updatedFilters[category]
        return updatedFilters
      })
    } else {
      setSelectedFilters({})
    }
  }

  return (
    <FilterSelectionContext.Provider
      value={useDeepCompareMemo(
        () => ({ selectedFilters, addFilter, removeFilter, clearFilters, setSelectedFilters }),
        [selectedFilters],
      )}
    >
      {children}
    </FilterSelectionContext.Provider>
  )
}
