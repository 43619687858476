import { renderToString } from 'react-dom/server'
import { getServerState } from 'react-instantsearch-core'
import type { GetServerSideProps } from 'next'
import type { Locale } from 'next/router'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import env from '@beam-australia/react-env'
import type { FooterProps } from '@knauf-group/ct-designs/components/core/Footer'
import { PageTitle } from '@knauf-group/ct-designs/components/core/PageTitle'
import type { HeaderMainMenu } from '@knauf-group/ct-designs/components/core/WebHeader'
import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import { getAppConfigProps } from '@knauf-group/ct-shared-nextjs/client'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import { inferDeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { sharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web'
import { Stack, useMediaQuery } from '@mui/material'
import type { Theme } from '@mui/material/styles'
import qs from 'qs'
import { useTrackScrollPosition } from 'src/hooks/analytics/useTrackScrollPosition'
import { generateServerUrlFromGetServerSidePropsContext } from 'src/utils/generateServerUrlFromContext'
import { minifyTranslationsSSRConfig } from 'src/utils/minifyTranslationsSSRConfig'
import { splitLocale } from 'src/utils/plain/splitLocale'
import { removeIrrelevantCountriesFromAppsConfig } from 'src/utils/removeIrrelevantCountriesFromAppsConfig'
import { withCache } from 'src/utils/withCache'

import DownloadCenterPageLite from '@/components/DownloadCenterPageLite'
import { ExternalFilesButtons } from '@/components/ExternalFilesButtons'
import { Filters } from '@/components/Filters/Filters'
import { Layout, type LayoutProps } from '@/components/Layout'
import { SearchbarAndFiltersButton } from '@/components/SearchbarAndFiltersButton'
import { SearchResults } from '@/components/SearchResults'
import { SortByDropdown } from '@/components/SortByDropdown'
import { AlgoliaProvider, type AlgoliaProviderProps } from '@/contexts/AlgoliaProvider'
import { FilterSelectionProvider } from '@/contexts/FilterSelectionContext/FilterSelectionProvider'
import { SearchProvider } from '@/contexts/SearchContext/SearchProvider'
import { usePageView } from '@/hooks'

import nextI18NextConfig from '../../../../next-i18next.config'

type PageProps = Omit<LayoutProps, 'headInfo'> & {
  algoliaProviderProps: AlgoliaProviderProps
}

const AnalyticsHooks = () => {
  usePageView()
  useTrackScrollPosition()

  return <Stack display="none" />
}

const Page = ({
  algoliaProviderProps,
  appsConfig,
  footerEntries,
  headerEntries,
}: PageProps) => {
  const { t } = useTranslation('download-center')

  const { locale } = useRouter()

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  return (
    <Layout
      appsConfig={appsConfig}
      footerEntries={footerEntries}
      headerEntries={headerEntries}
      headInfo={{
        pageCanonicalHref: `${env('HOST_BASE_URL')}/${locale}/tools/download-center`,
        pageDescription: t('mainPage.meta.description'),
        pageTitle: 'Download Center | Knauf',
      }}
    >
      <AlgoliaProvider {...algoliaProviderProps}>
        <SearchProvider>
          <FilterSelectionProvider>
            <AnalyticsHooks />

            <Stack
              gap={{ xs: 2, sm: 3 }}
              sx={{ pt: { xs: 0, md: 3 }, pb: { xs: 2, sm: 3, md: 6 } }}
            >
              <Stack direction="row" justifyContent="space-between">
                <PageTitle pageTitle="Download Center" />

                {isDesktop && <SortByDropdown dataCyPrefix="sort-by-desktop" />}
              </Stack>

              <ExternalFilesButtons />

              <SearchbarAndFiltersButton />

              {/* Filters for all devices */}
              <Filters />

              {/* `Show more` stuff is inside `SearchResultsTable`, also `no results` and `download` components */}
              <SearchResults />
            </Stack>
          </FilterSelectionProvider>
        </SearchProvider>
      </AlgoliaProvider>
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps<PageProps> = async (context) => {
  const locale = context.locale as Locale
  const { language, country } = splitLocale(locale)
  const { query } = context

  // TODO open for improvement??
  // if language is not in the url queries, then redirect this request to the same page but with language query
  if (!('language[0]' in query)) {
    const { resolvedUrl } = context

    const allQueriesString = qs.stringify(
      { ...query, language: [language] },
      { addQueryPrefix: true },
    )
    // get only the base path, without queries
    const basePath = resolvedUrl.split('?')[0]
    const destination = `/${locale}${basePath}${allQueriesString}`

    return {
      redirect: {
        destination,
        permanent: false,
      },
    }
  }

  const serverUrl = generateServerUrlFromGetServerSidePropsContext(context)
  const deviceType = context.req?.headers['user-agent']
    ? inferDeviceType(context.req?.headers['user-agent'])
    : undefined
  /// ///////////////////////////////////////////

  const [{ headerEntries, footerEntries }, translations, appsConfigProps, serverState] =
    await Promise.all([
      // contentful props are cached in local files
      sharedContentfulProps({ locale }).catch(() => {
        logger.error('Error while fetching shared contentful props', { locale })

        return { headerEntries: [] as HeaderMainMenu[], footerEntries: {} as FooterProps }
      }),
      // translations are cached by next-i18next already
      serverSideTranslations(
        locale,
        ['download-center', 'dam-assets', 'cms'],
        nextI18NextConfig,
      ).then(minifyTranslationsSSRConfig(locale, nextI18NextConfig.i18n.defaultLocale)),
      withCache({
        key: 'appsConfigProps',
        defaultValue: { appsConfig: {} as IAppsConfig },
        expirationInSec: 60,
        fetchIfMissingFn: getAppConfigProps,
      }),
      getServerState(
        // TODO where to put PageLite?
        <DownloadCenterPageLite
          serverUrl={serverUrl}
          locale={locale}
          deviceType={deviceType}
        />,
        { renderToString },
      ),
    ])

  const algoliaProviderProps = { serverUrl, serverState, locale, deviceType }

  return {
    props: {
      ...removeIrrelevantCountriesFromAppsConfig(appsConfigProps, country),
      ...translations,
      algoliaProviderProps,
      footerEntries,
      headerEntries,
    },
  }
}

export default Page
