import type { GetServerSidePropsContext, PreviewData } from 'next'

import type { ParsedUrlQuery } from 'querystring'

import { browseClient, getAlgoliaIndex, getAlgoliaIndexName } from '@/services/algolia'
import type { AssetModel } from '@/types'

import { generateSitemap } from './generateSitemap'
import { getLanguageAndCountry } from './getLanguageAndCountry'

export const getSitemap = async (
  ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
) => {
  const { locale, req, res } = ctx
  const { countryCode } = getLanguageAndCountry(locale)

  const protocol = req.headers.host?.includes('localhost') ? 'http' : 'https'
  const reqURL =
    req.url && req.url.includes('/sitemap.xml') ? req.url.replace('/sitemap.xml', '') : ''
  const baseUrl = `${protocol}://${req.headers.host}`

  const serverUrl = `${baseUrl}/${locale}${reqURL}`
  const downloadCenterApiUrl = `${baseUrl}/api/download-center/v1/assets/`

  let docs: AssetModel[] = []

  const indexName = getAlgoliaIndexName(countryCode)
  const index = await getAlgoliaIndex(browseClient, indexName)

  if (index) {
    await index.browseObjects<AssetModel>({
      query: '',
      filters: '',
      analytics: false,
      attributesToRetrieve: ['assetId', 'lastUpdated'],
      batch: (batch) => {
        docs = docs.concat(batch)
      },
    })
  }

  const sitemap = generateSitemap(
    countryCode.toLowerCase(),
    serverUrl,
    downloadCenterApiUrl,
    docs,
  )

  res.setHeader('Content-Type', 'application/xml')
  res.write(sitemap)
  res.end()

  return {
    props: {},
  }
}
