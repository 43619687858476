import { useCallback, useEffect, useState } from 'react'
import { useInfiniteHits } from 'react-instantsearch-core'

import queryStrings from 'query-string'
import { getActionForDownloadEvent } from 'src/utils/getActionForDownloadEvent'

import { DOWNLOAD_CENTER_SERVICE_URL } from '@/constants'
import type { CartState } from '@/contexts/CartContext/types'
import type { SelectedAsset } from '@/types'

import { useAnalyticsTracking } from './analytics'
import { useLanguageAndCountry } from './useLanguageAndCountry'

const createDateTimeStringForDownloadLink = () => {
  const padZero = (num: number) => num.toString().padStart(2, '0')

  const now = new Date()

  const year = now.getFullYear()
  const month = padZero(now.getMonth() + 1) // january is 0
  const day = padZero(now.getDate())
  const hour = padZero(now.getHours())
  const minute = padZero(now.getMinutes())
  const second = padZero(now.getSeconds())

  // e.g. 20240830235944
  return [year, month, day, hour, minute, second].join('')
}

export const useDownloadAssets = (state: CartState, assets: SelectedAsset[]) => {
  const [href, setHref] = useState('')
  const { trackDownload } = useAnalyticsTracking()
  const { sendEvent } = useInfiniteHits()
  const { countryCode } = useLanguageAndCountry()

  const assetIds = assets.map((a) => a.assetId)

  const updateHref = useCallback(() => {
    const filename = `Archive_${createDateTimeStringForDownloadLink()}`
    const query = queryStrings.stringify({
      ids: assetIds,
      filename,
      country: countryCode.toLowerCase(),
    })

    setHref(`${DOWNLOAD_CENTER_SERVICE_URL}/archive?${query}`)
  }, [assetIds])

  useEffect(() => {
    updateHref()
  }, [updateHref])

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    updateHref()
    sendEvent('click', assets, 'click download assets')
    trackDownload(getActionForDownloadEvent(e), href, Object.values(state.selectedAssets))
  }

  return { href, handleOnClick }
}
