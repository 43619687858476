import { toXML } from 'jstoxml'

import type { AssetModel } from '@/types'

import { formatDate } from './formatDate'

export const generateSitemap = (
  countryCode: string,
  serverUrl: string,
  downloadCenterApiUrl: string,
  docs: AssetModel[],
) => {
  return toXML(
    // content
    {
      _name: 'urlset',
      _attrs: {
        xmlns: 'http://www.sitemaps.org/schemas/sitemap/0.9',
        'xmlns:xhtml': 'http://www.w3.org/1999/xhtml',
      },
      _content: [
        {
          url: {
            loc: serverUrl,
            changefreq: 'daily',
            priority: '1.0',
            lastmod: formatDate(new Date()),
          },
        },
        ...docs.map((doc) => ({
          url: {
            loc: `${downloadCenterApiUrl}${doc.assetId}?${new URLSearchParams({
              download: 'true',
              country: countryCode,
            }).toString()}`,
            changefreq: 'daily',
            priority: '1.0',
            lastmod: formatDate(Number(doc.lastUpdated)),
          },
        })),
      ],
    },
    // config
    {
      header: true, // usual `<?xml version="1.0" encoding="UTF-8"?>` header
      indent: '  ', // response is minimized by default
    },
  )
}
